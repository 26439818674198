import React, { useState } from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

// Resources
import mainDesktop from "../../../assets/heros/Main.svg";
import swapDesktop from "../../../assets/heros/Swap.svg";
import farmDesktop from "../../../assets/heros/Farm.svg";
import poolsDesktop from "../../../assets/heros/Pools.svg";
import mainMobile from "../../../assets/heros/mobile/main-xs.svg";
import swapMobile from "../../../assets/heros/mobile/swap-xs.svg";
import farmMobile from "../../../assets/heros/mobile/farm-xs.svg";
import poolsMobile from "../../../assets/heros/mobile/pools-xs.svg";

const SOURCE = {
  desktop: {
    main: mainDesktop,
    swap: swapDesktop,
    farm: farmDesktop,
    pools: poolsDesktop,
  },
  mobile: {
    main: mainMobile,
    swap: swapMobile,
    farm: farmMobile,
    pools: poolsMobile,
  },
};

function HeroSection({ page }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [loaded, setLoaded] = useState(false);
  const imageStyle = !loaded ? { display: "none" } : {};

  return (
    <>
      {isMobile ? (
        <Box display="flex" width="100%" mt="10px">
          <img
            src={SOURCE.mobile[page]}
            alt="hero section"
            width="100%"
            height="auto"
          />
        </Box>
      ) : (
        <Box display="flex" width="100%" mt="30px">
          {!loaded && <Skeleton variant="rect" width="100%" height={240} />}
          <img
            src={SOURCE.desktop[page]}
            alt="hero section"
            width="100%"
            height="auto"
            style={imageStyle}
            onLoad={() => setLoaded(true)}
          />
        </Box>
      )}
    </>
  );
}

HeroSection.propTypes = {
  page: PropTypes.string.isRequired,
};

export default HeroSection;
