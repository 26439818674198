import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Navbar from "./views/sections/NavBar";
import Footer from "./views/sections/Footer";
import { default as LoadPage } from "./utils/loadable";

// test
import SwapPageTest from "./views/pages/swap";

const MainPage = LoadPage("Main");
const FarmingPage = LoadPage("Farm");
const SwapPage = LoadPage("Swap");
const PoolsPage = LoadPage("Pools");
const InfoPage = LoadPage("Info");

const AppRouter = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <div
          style={{
            position: "relative",
            minHeight: "80vh",
          }}
        >
          <div>
            <Route exact path="/" component={MainPage} />
            <Route path="/reserve" component={SwapPage} />
            <Route path="/farms" component={FarmingPage} />
            <Route path="/pools" component={PoolsPage} />
            <Route path="/info" component={InfoPage} />
          </div>
        </div>
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default AppRouter;
