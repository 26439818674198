import React from "react";
import { Typography, Grid, Box, List, ListItem } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddToken from '../../../components/AddToken/index'
import { IsMobile } from "../../../utils/mobile";
import MobileFooter from './indexMobile'
import DesktopFooter from './indexDesktop'

const useStyles = makeStyles({
  root: {
    bottom: 0,
    width: "100%",
  },
  socialList: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  footer: {
    color: "#8F9688",
    "&:hover": {
      color: "#6DA024",
    },
  },
  footerNoHover: {
    color: "#8F9688",
  },
  icons: {
    fill: "#8F9688",
    width: "24px",
    "&:hover": {
      fill: "#6DA024",
    },
  },
  whiteIcon: {
    fill: "white",
    width: "24px",
  },
});

const FooterText = ({ link, disableHover, children }) => {
  const classes = useStyles();

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={disableHover ? classes.footerNoHover : classes.footer}
    >
      <Typography
        variant="body2"
        className={disableHover ? classes.footerNoHover : classes.footer}
      >
        {children}
      </Typography>
    </a>
  );
};


/**
 * Responsive footer container
 * if the user switch to mobile mode, the footer will not be sticky.
 */
const FooterContainer = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();
  return useMediaQuery(theme.breakpoints.up("md")) ? (
    <Box width="100%" mt="80px" mb="10px" className={classes.root}>
      {children}
    </Box>
  ) : (
    <Box marginTop="20px" width="100%" style={{ backgroundColor: "#fafafa" }}>
      {children}
    </Box>
  );
};

const Footer = () => {
  const classes = useStyles();

  return IsMobile() ? <MobileFooter /> : <DesktopFooter />
};

export default Footer;
