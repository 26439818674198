import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"

const INFURA_ID = "91516e24ebff4dac8f2bd04b829f102d";

const METAMASK = 'METAMASK'
const WALLET_CONNECT = 'WALLET_CONNECT'

const metamaskInjector = () => {
    return new InjectedConnector({
        supportedChainIds: [56],
    })
}

const walletConnectInjector = () => {
    return new WalletConnectConnector({
          infuraId: INFURA_ID,
          rpc: { [56]: 'https://bsc-dataseed1.ninicoin.io/' },
          bridge: 'https://pancakeswap.bridge.walletconnect.org/',
          qrcode: true,
          pollingInterval: 12000,
    })
}

const createInjector = (name) => {
    switch(name) {
        case WALLET_CONNECT:
            return walletConnectInjector()
        case METAMASK:
            return metamaskInjector()
        default:
            return null

    }
}

export { METAMASK, WALLET_CONNECT }
export { createInjector }